import { useStyles } from '../../hooks/useStyles';
import { ButtonComponent } from '../../types/componentTypes';

export function Button({
  children,
  classNames,
  onClick,
  disabled = false,
  kind = 'primary',
  outline = false,
  size = 'default',
  type = 'button',
}: Readonly<ButtonComponent>) {
  const { classes, concatenateClasses, theme, css } = useStyles();

  const buttonColor = (() => {
    if (outline) {
      return 'transparent';
    }
    switch (kind) {
      case 'secondary':
        return theme.colors.secondary[0];
      case 'accent':
        return theme.colors.accent[0];
      default:
        return theme.colors.primary[0];
    }
  })();

  const buttonForegroundColor = (() => {
    if (outline) {
      switch (kind) {
        case 'secondary':
          return theme.colors.secondary[0];
        case 'accent':
          return theme.colors.accent[0];
        default:
          return theme.colors.primary[0];
      }
    }
    return '#ffffff';
  })();

  const buttonPadding = (() => {
    if (size === 'large') {
      return `${theme.spacing.lg} ${theme.spacing.xl}`;
    }
    return `${theme.spacing.md} ${theme.spacing.lg}`;
  })();

  const buttonBorder = (() => {
    if (outline) {
      return `1px solid ${buttonForegroundColor}`;
    }
    return 'none';
  })();

  const buttonStyles = css({
    backgroundColor: buttonColor,
    color: buttonForegroundColor,
    padding: buttonPadding,
    border: buttonBorder,
  });

  const buttonAllStyles = concatenateClasses(classes.button, buttonStyles, classNames ?? '');

  return (
    <button disabled={disabled} type={type} onClick={onClick} className={buttonAllStyles}>
      {children}
    </button>
  );
}
