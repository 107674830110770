import { useStyletron } from 'styletron-react';
import THEME from '../constants/theme';

export function useStyles() {
  const [css] = useStyletron();
  const theme = THEME;

  const mediaQueries = {
    xs: `@media screen and (min-width: ${theme.breakpoints.xs})`,
    sm: `@media screen and (min-width: ${theme.breakpoints.sm})`,
    md: `@media screen and (min-width: ${theme.breakpoints.md})`,
    lg: `@media screen and (min-width: ${theme.breakpoints.lg})`,
    xl: `@media screen and (min-width: ${theme.breakpoints.xl})`,
    xxl: `@media screen and (min-width: ${theme.breakpoints.xxl})`,
  };

  const imgResponsive = css({
    maxWidth: '100%',
  });

  const flexColumn = css({
    display: 'flex',
    flexDirection: 'column',
  });

  const flexRow = css({
    display: 'flex',
  });

  const flexCentered = css({
    alignItems: 'center',
    justifyContent: 'center',
  });

  const link = css({
    color: 'inherit',
    textDecoration: 'none',
  });

  const container = css({
    maxWidth: '1408px',
    margin: '0 auto',
    paddingRight: '5%',
    paddingLeft: '5%',
    [mediaQueries.lg]: {
      paddingRight: '10%',
      paddingLeft: '10%',
    },
  });

  const section = css({
    paddingTop: '60px',
    paddingBottom: '60px',
  });

  const button = css({
    borderRadius: theme.sizing.sm,
    cursor: 'pointer',
    fontWeight: '500',
  });

  const textSecondary = css({
    color: theme.colors.secondary[0],
  });

  const fullWidth = css({
    width: '100%',
  });

  const sectionTitle = css({
    fontSize: '38px',
    fontWeight: '500',
    lineHeight: '1',
    [mediaQueries.lg]: {
      fontSize: '50px',
    },
  });

  const cardTitle = css({
    fontSize: theme.sizing.xxl,
    fontWeight: '500',
  });

  const cardDescription = css({
    fontSize: theme.sizing.lg,
  });

  const backgroundLight = css({
    backgroundColor: theme.colors.lightBackground,
  });

  const displayBlock = css({
    display: 'block',
  });

  const concatenateClasses = (...classes: string[]) => {
    return classes.join(' ');
  };

  return {
    concatenateClasses,
    css,
    mediaQueries,
    theme,
    classes: {
      backgroundLight,
      button,
      cardDescription,
      cardTitle,
      container,
      displayBlock,
      flexCentered,
      flexColumn,
      flexRow,
      fullWidth,
      imgResponsive,
      link,
      section,
      sectionTitle,
      textSecondary,
    },
  };
}
