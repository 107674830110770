import LocalizedStrings from 'react-localization';
import { selector } from 'recoil';
import { EN_LANGUAGE, ES_LANGUAGE, LANGUAGES, strings } from '../constants/locales';
import { IStrings, IStringsWithMethods } from '../types/localeTypes';
import { persistedAtom } from '../utils/persistence';

export const currentLanguageState = persistedAtom<string>(
  'locale.language.selected',
  (() => {
    const navigatorLanguage = window.navigator.languages[0];
    if (LANGUAGES.includes(navigatorLanguage)) {
      return navigatorLanguage;
    }
    return LANGUAGES[0];
  })()
);

export const stringsState = selector({
  key: 'locale.strings',
  get: ({ get }) => {
    const lang = get(currentLanguageState);
    const localizedStrings: IStringsWithMethods = new LocalizedStrings<IStrings>(strings);
    localizedStrings.setLanguage(lang);
    return localizedStrings;
  },
});

export const esRoutesState = selector({
  key: 'locale.strings.es',
  get: () => {
    const localizedStrings: IStringsWithMethods = new LocalizedStrings<IStrings>(strings);
    localizedStrings.setLanguage(ES_LANGUAGE);
    return localizedStrings;
  },
});

export const enRoutesState = selector({
  key: 'locale.strings.en',
  get: () => {
    const localizedStrings: IStringsWithMethods = new LocalizedStrings<IStrings>(strings);
    localizedStrings.setLanguage(EN_LANGUAGE);
    return localizedStrings;
  },
});
