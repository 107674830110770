import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import App from './App';
import './index.css';

const engine = new Styletron();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <StyletronProvider value={engine}>
        <App />
      </StyletronProvider>
    </RecoilRoot>
  </React.StrictMode>
);
