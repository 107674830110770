import { useStyles } from '../../../hooks/useStyles';

type Props = {
  service: {
    title: string;
    description: string;
  };
  showDecorator?: boolean;
};
export function ServiceCard({ service, showDecorator = false }: Readonly<Props>) {
  const { css, theme, mediaQueries, concatenateClasses, classes } = useStyles();

  const cardStyles = concatenateClasses(
    classes.flexColumn,
    classes.flexCentered,
    css({
      backgroundColor: theme.colors.primary[1],
      borderRadius: theme.sizing.md,
      color: '#ffffff',
      flex: 1,
      gap: theme.spacing.xl,
      minHeight: '200px',
      minWidth: '200px',
      padding: `20px 40px`,
      position: 'relative',
      textAlign: 'center',
      [mediaQueries.lg]: {
        minHeight: '300px',
      },
    })
  );

  const titleStyles = concatenateClasses(
    classes.cardTitle,
    css({
      color: theme.colors.primary[2],
    })
  );

  const descriptionStyles = classes.cardDescription;

  const decoratorStyles = css({
    bottom: 0,
    left: 0,
    maxWidth: '40px',
    position: 'absolute',
    transform: 'translateX(-50%)',
    [mediaQueries.xl]: {
      maxWidth: '50px',
    },
  });

  return (
    <div className={cardStyles}>
      {showDecorator && (
        <img src="../assets/img/icons/Gradient_fig1.svg" alt="Boleh decorator" className={decoratorStyles} />
      )}
      <p className={titleStyles}>{service.title}</p>
      <p className={descriptionStyles}>{service.description}</p>
    </div>
  );
}
