const THEME = {
  colors: {
    primary: ['#011744', '#001e57', '#6c8dff'],
    secondary: ['#7668ff', '#6c62f3'],
    accent: ['#40c880', '#36b070'],
    background: '#ffffff',
    lightBackground: '#faf6f2',
  },
  breakpoints: {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
  sizing: {
    xs: '2px',
    sm: '6px',
    md: '12px',
    lg: '16px',
    xl: '24px',
    xxl: '32px',
  },
  spacing: {
    zero: '0px',
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '12px',
    xl: '16px',
    xxl: '20px',
  },
};

export default THEME;
