import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { WhatsappButton } from '../shared/WhatsappButton';

export function ContactSection() {
  const { concatenateClasses, classes, css, theme, mediaQueries } = useStyles();
  const { strings } = useStrings();

  const contentWrapperStyles = classes.backgroundLight;

  const infoWrapperStyles = concatenateClasses(
    classes.flexRow,
    css({
      marginTop: theme.spacing.xxl,
      alignItems: 'flex-start',
      gap: '30px',
      flexWrap: 'wrap',
      [mediaQueries.lg]: {
        gap: '100px',
      },
    })
  );

  const headlineStyles = css({
    textAlign: 'center',
    color: theme.colors.secondary[0],
  });

  const sectionStyles = concatenateClasses(
    classes.container,
    classes.section,
    classes.flexColumn,
    classes.flexCentered,
    css({
      gap: theme.spacing.xxl,
    })
  );

  const infoStyles = css({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: theme.spacing.lg,
    minWidth: '300px',
    textAlign: 'center',
    [mediaQueries.lg]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      gap: theme.spacing.xxl,
      textAlign: 'start',
    },
  });

  const infoLabel = css({
    fontWeight: '500',
  });

  const phoneStyles = concatenateClasses(classes.link, classes.displayBlock);

  return (
    <div className={contentWrapperStyles}>
      <section id={strings.sections.contact.id} className={sectionStyles}>
        <h1 className={classes.sectionTitle}>{strings.contact.title}</h1>
        <p className={headlineStyles}>{strings.contact.headline}</p>
        <WhatsappButton />
        {/*<div className={infoWrapperStyles}>*/}
        {/*  <div className={infoStyles}>*/}
        {/*    <p className={infoLabel}>{strings.contact.address}</p>*/}
        {/*    <div>*/}
        {/*      <p dangerouslySetInnerHTML={{ __html: strings.contact.addressText }} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={infoStyles}>*/}
        {/*    <p className={infoLabel}>{strings.contact.cellphone}</p>*/}
        {/*    <div>*/}
        {/*      {Object.values(strings.contact.cellphones).map((cellphone, index) => (*/}
        {/*        <a href={`tel:${cellphone}`} key={index} className={phoneStyles}>*/}
        {/*          {cellphone}*/}
        {/*        </a>*/}
        {/*      ))}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </section>
    </div>
  );
}
