import { IStrings } from '../types/localeTypes';

export const ES_STRINGS: IStrings = {
  sections: {
    aboutUs: {
      id: 'nosotros',
      name: 'Nosotros',
    },
    services: {
      id: 'servicios',
      name: 'Servicios',
    },
    portfolio: {
      id: 'portafolio',
      name: 'Portafolio',
    },
    contact: {
      id: 'contacto',
      name: 'Contacto',
    },
  },
  hero: {
    title: 'Creando soluciones de',
    subtitle: 'software a la medida',
    description:
      'Ofrecemos\n' +
        'soluciones tecnológicas personalizadas que impulsan la eficiencia y el\n' +
        'crecimiento de nuestros clientes.',
  },
  trustedBy: {
    title: 'Estas empresas confían en nosotros',
    alliades: {
      1: {
        url: '../assets/img/clients/encontrack.png',
      },
      2: {
        url: '../assets/img/clients/hellotracks.svg',
      },
      3: {
        url: '../assets/img/clients/icorp.svg',
      },
      4: {
        url: '../assets/img/clients/karma.png',
      },
      5: {
        url: '../assets/img/clients/tecnipesa.png',
      },
      6: {
        url: '../assets/img/clients/textiles.png',
      },
      7: {
        url: '../assets/img/clients/yeti.png',
      },
      8: {
        url: '../assets/img/clients/bikextore.png',
      },
      9: {
        url: '../assets/img/clients/boletera.png',
      },
      10: {
        url: '../assets/img/clients/gorila.png',
      },
      11: {
        url: '../assets/img/clients/bongosbingo.png',
      },
    },
  },
  services: {
    title: 'Servicios',
    list: {
      1: {
        title: 'Desarrollo Web',
        description:
            '',
      },
      2: {
        title: 'Desarrollo Móvil',
        description:
          '',
      },
      3: {
        title: 'Diseño de Usuario (UI) y Experiencia de Usuario (UX)',
        description:
          '',
      },
      4: {
        title: 'Integraciones B2B',
        description:
            '',
      },
      5: {
        title: 'Automatización de procesos',
        description:
            '',
      },
      6: {
        title: 'Servicios de consultoría en Tecnología',
        description:
            '',
      },
      7: {
        title: 'Servicios Cloud AWS',
        description:
            '',
      },
      8: {
        title: 'IoT',
        description:
            '',
      },
      9: {
        title: 'Mantenimiento y Soporte',
        description:
            '',
      },
    },
  },
  portfolio: {
    headline: 'La forma más rápida de convertir<br/>una idea en realidad',
    title: 'Portafolio',
    list: {
      1: {
        title: 'Desarrollo de Frontend y Backend',
        description: 'Encontrack',
        imageUrl: '../assets/img/portfolio/floatcon.png',
      },
      2: {
        title: 'Desarrollo Web',
        description: 'Creamos sitios web personalizados',
        imageUrl: '../assets/img/portfolio/josecastillo.png',
      },
      3: {
        title: 'Integraciones de Hardware / IOT',
        description: 'Textiles agrícolas',
        imageUrl: '../assets/img/portfolio/textiles.png',
      },
      4: {
        title: 'Aplicaciones Móviles',
        description: 'Hellotracks',
        imageUrl: '../assets/img/portfolio/hellotracks.png',
      },
    },
  },
  contact: {
    title: 'Contacto',
    headline: 'No dudes en contactarnos, con gusto te atenderemos',
    address: 'Dirección',
    cellphone: 'Celular',
    addressText: 'Av. Prolongación Tecnológico #700-Oficina 1, Plaza Fresnos,<br />Col. Los Fresnos, 76150<br />Querétaro, México',
    cellphones: {
      0: '4426699785'
    },
  },
  shared: {
    whatsappPhone: '524426699785',
    whatsappButton: 'Cotiza tu proyecto',
    whatsappCustomMessage: '',
    facebookUrl: '',
    instagramUrl: '',
    copyright: '2023 Boleh. Todos los derechos reservados',
  },
};
