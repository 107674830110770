import { useStyles } from '../../../hooks/useStyles';

type Props = {
  project: {
    title: string;
    description: string;
    imageUrl: string;
  };
};

export function ProjectCard({ project }: Readonly<Props>) {
  const { css, theme, mediaQueries, concatenateClasses, classes } = useStyles();

  const cardStyles = concatenateClasses(
    classes.flexRow,
    classes.flexCentered,
    css({
      backgroundImage: `url(${project.imageUrl})`,
      backgroundPosition: 'top left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      color: '#ffffff',
      flex: 1,
      minHeight: '200px',
      minWidth: '300px',
      position: 'relative',
      [mediaQueries.lg]: {
        minHeight: '300px',
      },
    })
  );
  const titleStyles = classes.cardTitle;

  const descriptionStyles = classes.cardDescription;

  const contentStyles = concatenateClasses(
    classes.flexColumn,
    css({
      background:
        'linear-gradient(to bottom, rgba(0, 30, 87, 0.5) 0% 60%, rgba(1, 23, 68, 0.5) 70%, rgba(1, 23, 68, 0.8) 100%)',
      bottom: 0,
      gap: theme.spacing.md,
      justifyContent: 'end',
      left: 0,
      padding: theme.spacing.xxl,
      position: 'absolute',
      right: 0,
      top: 0,
    })
  );

  return (
    <div className={cardStyles}>
      <div className={contentStyles}>
        <p className={titleStyles}>{project.title}</p>
        <p className={descriptionStyles}>{project.description}</p>
      </div>
    </div>
  );
}
