import { EN_STRINGS } from '../locales/en';
import { ES_STRINGS } from '../locales/es';

export const ES_LANGUAGE = 'es';
export const EN_LANGUAGE = 'en';

export const LANGUAGES = [ES_LANGUAGE, EN_LANGUAGE];

export const strings = {
  en: EN_STRINGS,
  es: ES_STRINGS,
};
