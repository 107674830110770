import { ContactSection } from '../home/ContactSection';
import { HeroSection } from '../home/HeroSection';
import { PortfolioSection } from '../home/PortfolioSection';
import { ServicesSection } from '../home/ServicesSection';
import { TrustedBySection } from '../home/TrustedBySection';
import { BaseLayout } from '../layouts/BaseLayout';

export function HomePage() {
  return (
    <BaseLayout>
      <HeroSection />
      <TrustedBySection />
      <ServicesSection />
      <PortfolioSection />
      <ContactSection />
    </BaseLayout>
  );
}
