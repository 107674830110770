import { useRecoilValue } from 'recoil';
import { stringsState } from '../storage/localeStates';
import { IStringsWithMethods } from '../types/localeTypes';
import { KeyOfLocales } from '../types/objectTypes';

export function useStrings() {
  const strings = useRecoilValue(stringsState);

  const getStringByKey = (key: KeyOfLocales) => {
    return strings.getString(key);
  };

  const getStringByKeyFromStrings = (strings: IStringsWithMethods, key: KeyOfLocales) => {
    return strings.getString(key);
  };

  return { strings, format: strings.formatString, getStringByKey, getStringByKeyFromStrings };
}
