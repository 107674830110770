import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { WhatsappButton } from '../shared/WhatsappButton';

export function HeroSection() {
  const { classes, css, concatenateClasses, mediaQueries, theme } = useStyles();
  const { strings } = useStrings();

  const iconStyles = css({
    bottom: 0,
    left: 0,
    maxWidth: '100px',
    position: 'absolute',
    transform: 'translate(-85%, 20px)',
    [mediaQueries.md]: {
      maxWidth: '150px',
      transform: 'translate(-25%, 30px)',
    },
    [mediaQueries.xl]: {
      maxWidth: '200px',
      transform: 'translate(-50%, 30px)',
    },
  });

  const heroContent = concatenateClasses(
    classes.flexColumn,
    css({
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      gap: theme.spacing.xxl,
      justifyContent: 'center',
      minHeight: '200px',
      padding: '0 5%',
      position: 'relative',
      width: '100%',
      [mediaQueries.md]: {
        padding: '0 20%',
      },
    })
  );

  const titleStyles = concatenateClasses(
    classes.fullWidth,
    classes.sectionTitle,
    css({
      lineHeight: '1.3',
    })
  );

  const subtitleStyles = concatenateClasses(
    classes.textSecondary,
    css({
      fontWeight: '400',
    })
  );

  const decoratorStyles = css({
    maxWidth: '40px',
    position: 'absolute',
    right: '20%',
    top: 0,
    transform: 'translate(150%, -50%)',
    [mediaQueries.md]: {
      transform: 'translate(100%, -50%)',
    },
    [mediaQueries.xl]: {
      maxWidth: '50px',
    },
  });

  const sectionStyles = concatenateClasses(classes.container, classes.section);

  return (
    <section id={strings.sections.aboutUs.id} className={sectionStyles}>
      <div className={heroContent}>
        <img src="../assets/img/logos/Gradient_symbol.svg" alt="Boleh icon" className={iconStyles} />
        <img src="../assets/img/icons/Gradient_fig1.svg" alt="Boleh decorator" className={decoratorStyles} />
        <h1 className={titleStyles}>
          {strings.hero.title}
          <br />
          <span className={subtitleStyles}>{strings.hero.subtitle}</span>
        </h1>
        <p>{strings.hero.description}</p>
        <WhatsappButton />
      </div>
    </section>
  );
}
