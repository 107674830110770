import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { ProjectCard } from './portfolio/ProjectCard';

export function PortfolioSection() {
  const { concatenateClasses, classes, css, theme, mediaQueries } = useStyles();
  const { strings } = useStrings();

  const contentWrapperStyles = css({
    background: `linear-gradient(to bottom, ${theme.colors.background} 0% 70%, ${theme.colors.lightBackground} 70% 100%)`,
  });

  const headlineStyles = concatenateClasses(
    classes.sectionTitle,
    css({
      color: theme.colors.secondary[0],
      marginBottom: '60px',
      textAlign: 'center',
    })
  );

  const servicesWrapperStyles = concatenateClasses(
    classes.flexRow,
    css({
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing.xxl,
      marginTop: theme.spacing.xxl,
      position: 'relative',
    })
  );

  const decoratorStyles = css({
    maxWidth: '40px',
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translate(150%, -50%) rotate(270deg)',
    [mediaQueries.xl]: {
      maxWidth: '50px',
    },
  });

  const sectionStyles = concatenateClasses(classes.container, classes.section);

  const titleStyles = classes.sectionTitle;

  return (
    <div className={contentWrapperStyles}>
      <section id={strings.sections.portfolio.id} className={sectionStyles}>
        <p className={headlineStyles} dangerouslySetInnerHTML={{ __html: strings.portfolio.headline }} />
        <h1 className={titleStyles}>{strings.portfolio.title}</h1>
        <div className={servicesWrapperStyles}>
          <img src="../assets/img/icons/Gradient_fig2.svg" alt="Boleh decorator" className={decoratorStyles} />
          {Object.values(strings.portfolio.list).map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </section>
    </div>
  );
}
