import { NAVBAR_HEIGHT } from '../../constants/ui';
import { GenericComponent } from '../../types/componentTypes';
import { useStyles } from '../../hooks/useStyles';
import { Footer } from '../shared/Footer';
import { Navbar } from '../shared/Navbar';

export function BaseLayout({ children }: Readonly<GenericComponent>) {
  const { css } = useStyles();

  const contentWrapperStyles = css({
    minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
  });

  return (
    <>
      <Navbar />
      <div className={contentWrapperStyles}>{children}</div>
      <Footer />
    </>
  );
}
