import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { Button } from '../ui/Button';

export function WhatsappButton() {
  const { css, theme } = useStyles();
  const { strings } = useStrings();

  const buttonStyles = css({ display: 'flex', alignItems: 'center', gap: theme.spacing.md });

  const openWhatsApp = () => {
    window.open(`https://wa.me/${strings.shared.whatsappPhone}?text=${strings.shared.whatsappCustomMessage}`, '_blank');
  };

  return (
    <Button onClick={openWhatsApp} kind="accent" size="large" classNames={buttonStyles}>
      <img src="../assets/img/icons/whatsapp-icon.png" alt="" className={css({ height: '18px' })} />
      <span>{strings.shared.whatsappButton}</span>
    </Button>
  );
}
