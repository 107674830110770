import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';

export function TrustedBySection() {
  const { concatenateClasses, classes, css, theme, mediaQueries } = useStyles();
  const { strings } = useStrings();

  const contentWrapperStyles = classes.backgroundLight;

  const titleStyles = css({
    fontWeight: '500',
    textAlign: 'center',
  });

  const brandsWrapperStyles = concatenateClasses(
    classes.flexRow,
    classes.flexCentered,
    css({
      flexWrap: 'wrap',
      gap: theme.spacing.xxl,
      marginTop: theme.spacing.xxl,
      [mediaQueries.lg]: {
        gap: '50px',
      },
    })
  );

  const brandStyles = css({
    maxHeight: '40px',
    maxWidth: '100px',
    objectFit: 'contain',
    [mediaQueries.md]: {
      maxHeight: '60px',
      maxWidth: '150px',
    },
    [mediaQueries.xl]: {
      maxHeight: '80px',
      maxWidth: '200px',
    },
  });

  const sectionStyles = concatenateClasses(classes.container, classes.section);

  return (
    <div className={contentWrapperStyles}>
      <section className={sectionStyles}>
        <h1 className={titleStyles}>{strings.trustedBy.title}</h1>
        <div className={brandsWrapperStyles}>
          {Object.values(strings.trustedBy.alliades).map((alliade, index) => (
            <img key={index} src={alliade.url} alt="Brand logo" className={brandStyles} />
          ))}
        </div>
      </section>
    </div>
  );
}
