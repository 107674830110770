import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';

export function Footer() {
  const { classes, css, concatenateClasses, mediaQueries, theme } = useStyles();
  const { strings } = useStrings();

  const iconStyles = css({
    maxWidth: '100px',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(0, calc(-60px - 50%))',
    [mediaQueries.md]: {
      maxWidth: '150px',
    },
    [mediaQueries.xl]: {
      maxWidth: '200px',
    },
  });

  const footerContent = concatenateClasses(
    classes.flexColumn,
    css({
      alignItems: 'center',
      boxSizing: 'border-box',
      gap: theme.spacing.xxl,
      position: 'relative',
      width: '100%',
      [mediaQueries.md]: {
        alignItems: 'flex-start',
      },
    })
  );

  const sectionStyles = concatenateClasses(
    classes.container,
    classes.section,
    css({
      paddingBottom: '20px',
    })
  );

  const footerDataWrapper = css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    margin: `${theme.spacing.xl} 0`,
    width: '100%',
    [mediaQueries.md]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      gap: theme.spacing.xl,
    },
  });

  const footerDataColumn = concatenateClasses(
    classes.flexColumn,
    css({
      flex: 1,
      gap: theme.spacing.lg,
      textAlign: 'center',
      [mediaQueries.md]: {
        textAlign: 'left',
      },
    })
  );

  const siteWrapperStyles = concatenateClasses(
    footerDataColumn,
    css({
      fontWeight: '500',
      [mediaQueries.lg]: {
        alignItems: 'center',
      },
    })
  );

  const contactWrapperStyles = concatenateClasses(
    footerDataColumn,
    css({
      [mediaQueries.lg]: {
        alignItems: 'flex-end',
      },
    })
  );

  const logoStyles = css({
    height: '80px',
    objectFit: 'contain',
  });

  const phoneStyles = concatenateClasses(
    classes.link,
    css({
      width: 'fit-content',
    })
  );

  const copyrightStyles = css({
    fontSize: theme.sizing.md,
  });

  const contactContentStyles = concatenateClasses(
    classes.flexColumn,
    css({
      gap: theme.spacing.lg,
    })
  );

  const phonesWrapperStyles = concatenateClasses(
    classes.flexColumn,
    css({
      alignItems: 'center',
      gap: theme.spacing.md,
      [mediaQueries.md]: {
        alignItems: 'flex-start',
      },
    })
  );

  const socialStyles = concatenateClasses(
    classes.link,
    css({ display: 'flex', alignItems: 'center', gap: theme.spacing.md })
  );

  return (
    <section className={sectionStyles}>
      <div className={footerContent}>
        <img src="../assets/img/logos/Gradient_symbol.svg" alt="Boleh icon" className={iconStyles} />
        <img src="../assets/img/logos/Gradient1_logo.svg" alt="Boleh logo" className={logoStyles} />
        <div className={footerDataWrapper}>
          <div className={footerDataColumn}>
            {strings.shared.facebookUrl != "" && (
              <a href={strings.shared.facebookUrl} target="_blank" rel="noreferrer" className={socialStyles}>
                <img src="../assets/img/icons/facebook.svg" alt="" className={css({ height: '16px' })} />
                <span>Facebook</span>
              </a>
            )}
            {strings.shared.instagramUrl != "" && (
              <a href={strings.shared.instagramUrl} target="_blank" rel="noreferrer" className={socialStyles}>
                <img src="../assets/img/icons/instagram.svg" alt="" className={css({ height: '16px' })} />
                <span>Instagram</span>
              </a>
            )}
          </div>
          <div className={siteWrapperStyles}>
            {Object.values(strings.sections).map((section) => (
              <a key={section.id} href={`#${section.id}`} className={classes.link}>
                {section.name}
              </a>
            ))}
          </div>
          <div className={contactWrapperStyles}>
            <div className={contactContentStyles}>
              <p dangerouslySetInnerHTML={{ __html: strings.contact.addressText }} />
              <div className={phonesWrapperStyles}>
                {Object.values(strings.contact.cellphones).map((cellphone, index) => (
                  <a key={index} href={`tel:${cellphone}`} className={phoneStyles}>
                    {cellphone}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <p className={copyrightStyles}>{strings.shared.copyright}</p>
      </div>
    </section>
  );
}
