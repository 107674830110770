import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useWindowSize } from 'usehooks-ts';
import { EN_LANGUAGE, ES_LANGUAGE } from '../../constants/locales';
import { NAVBAR_HEIGHT } from '../../constants/ui';
import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { openMenuState } from '../../storage/appStates';
import { currentLanguageState } from '../../storage/localeStates';
import { Button } from '../ui/Button';

export function Navbar() {
  const { css, theme, classes, concatenateClasses, mediaQueries } = useStyles();
  const { strings } = useStrings();
  const [openMenu, setOpenMenu] = useRecoilState(openMenuState);
  const { width } = useWindowSize();
  const [language, setLanguage] = useRecoilState(currentLanguageState);

  const isMobile = width < 768;

  useEffect(() => {
    if (width >= 768) {
      setOpenMenu(true);
    } else {
      setOpenMenu(false);
    }
  }, [width]);

  const navbarStyles = css({
    boxShadow: openMenu ? '0px 3px 10px 0px #ebebeb' : 'none',
    boxSizing: 'border-box',
    minHeight: `${NAVBAR_HEIGHT}px`,
    padding: `${theme.spacing.md} 0 ${openMenu ? theme.spacing.xl : theme.spacing.md}`,
    [mediaQueries.md]: {
      boxShadow: 'none',
      height: `${NAVBAR_HEIGHT}px`,
      padding: `${theme.spacing.md} 0`,
    },
  });

  const logoStyles = css({
    height: '50px',
    objectFit: 'contain',
  });

  const navigationStyles = css({
    alignItems: 'start',
    display: openMenu ? 'flex' : 'none',
    flexDirection: 'column',
    gap: theme.spacing.xxl,
    justifyContent: 'center',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%',
    [mediaQueries.md]: {
      flexDirection: 'row',
      height: 'auto',
      width: 'auto',
    },
  });

  const navigationWrapper = concatenateClasses(
    classes.container,
    css({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing.xl,
      height: '100%',
      justifyContent: 'space-between',
      [mediaQueries.md]: {
        flexDirection: 'row',
      },
    })
  );

  const mainNav = concatenateClasses(
    classes.flexRow,
    css({
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      [mediaQueries.md]: {
        width: 'auto',
      },
    })
  );

  const menuStyles = css({
    background: 'transparent',
    [mediaQueries.md]: {
      display: 'none',
    },
  });

  const selectedLanguageStyles = css({
    fontWeight: '600',
  });

  const dividerStyles = css({
    background: theme.colors.primary[0],
    height: theme.sizing.lg,
    width: '1px',
  });

  const languageWrapperStyles = concatenateClasses(
    classes.flexRow,
    css({
      alignItems: 'center',
      gap: theme.spacing.md,
    })
  );

  const menuIconStyles = css({ height: '15px' });

  const menuWrapperStyles = concatenateClasses(classes.flexRow, css({ alignItems: 'center', gap: theme.spacing.md }));

  const toggleMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  const onNavClick = () => {
    if (isMobile && openMenu) {
      setOpenMenu(false);
    }
  };

  return (
    <nav className={navbarStyles}>
      <div className={navigationWrapper}>
        <div className={mainNav}>
          <img src="../assets/img/logos/Gradient1_logo.svg" alt="Boleh logo" className={logoStyles} />
          <Button onClick={toggleMenu} classNames={menuStyles} outline>
            <div className={menuWrapperStyles}>
              <img src="../assets/img/icons/Gradient_fig2.svg" alt="menu" className={menuIconStyles} />
              <span>menu</span>
            </div>
          </Button>
        </div>
        <ul className={navigationStyles}>
          {Object.values(strings.sections).map((section) => (
            <li key={section.id}>
              <a href={`#${section.id}`} className={classes.link} onClick={onNavClick}>
                {section.name}
              </a>
            </li>
          ))}
          <li>
            <div className={languageWrapperStyles}>
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setLanguage(ES_LANGUAGE);
                }}
                className={concatenateClasses(classes.link, language === ES_LANGUAGE ? selectedLanguageStyles : '')}
              >
                ES
              </a>
              <div className={dividerStyles} />
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setLanguage(EN_LANGUAGE);
                }}
                className={concatenateClasses(classes.link, language === EN_LANGUAGE ? selectedLanguageStyles : '')}
              >
                EN
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
