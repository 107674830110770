import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { ServiceCard } from './services/ServiceCard';

export function ServicesSection() {
  const { concatenateClasses, classes, css, theme, mediaQueries } = useStyles();
  const { strings } = useStrings();

  const contentWrapperStyles = css({
    background: theme.colors.primary[0],
    [mediaQueries.lg]: {
      background: `linear-gradient(to right, ${theme.colors.primary[0]} 0% 95%, #ffffff 95% 100%)`,
    },
  });

  const titleStyles = concatenateClasses(
    classes.sectionTitle,
    css({
      color: '#ffffff',
    })
  );

  const servicesWrapperStyles = concatenateClasses(
    classes.flexRow,
    css({
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing.xxl,
      marginTop: theme.spacing.xxl,
      [mediaQueries.xl]: {
        gap: '50px',
      },
    })
  );

  const sectionStyles = concatenateClasses(classes.container, classes.section);

  return (
    <div className={contentWrapperStyles}>
      <section id={strings.sections.services.id} className={sectionStyles}>
        <h1 className={titleStyles}>{strings.services.title}</h1>
        <div className={servicesWrapperStyles}>
          {Object.values(strings.services.list).map((service, index) => (
            <ServiceCard key={index} service={service} showDecorator={index === 0} />
          ))}
        </div>
      </section>
    </div>
  );
}
